<template>
    <div class="relative overflow-hidden bg-secondary-100">
        <div class="mx-auto max-w-7xl">
            <div class="relative z-10 pb-8 bg-secondary-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <svg
                    class="absolute inset-y-0 right-0 hidden w-48 h-full text-secondary-100 transform translate-x-1/2 lg:block"
                    fill="currentColor"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none"
                    aria-hidden="true"
                >
                    <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <Popover>
                    <div class="relative px-4 pt-6 sm:px-6 lg:px-8">
                        <nav class="relative flex items-center justify-between mx-auto max-w-7xl " aria-label="Global">
                            <div class="flex items-center flex-1">
                                <div class="flex items-center justify-between w-full md:w-auto">
                                    <div>
                                        <span class="sr-only">EXCESS Flexibility Marketplace</span>
                                        <img
                                            class="w-auto h-8 sm:h-10"
                                            src="@/assets/logo-on-white-excess.png"
                                            alt="EXCESS"
                                        />
                                    </div>
                                    <div class="flex items-center -mr-2 md:hidden">
                                        <button
                                            type="button"
                                            class="inline-flex items-center justify-center p-2 text-white bg-secondary-500 rounded-md hover:bg-secondary-600 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white"
                                            aria-expanded="false"
                                            @click="isMenuOpen = true"
                                        >
                                            <span class="sr-only">Open</span>
                                            <MenuIcon class="w-6 h-6" />
                                        </button>
                                    </div>
                                </div>
                                <div v-if="isAuthenticated" class="hidden h-8 pt-4 space-x-8 sm:h-10 md:flex md:ml-10">
                                    <router-link
                                        to="/overview"
                                        class="text-base font-medium text-primary-400 hover:text-primary-700"
                                        >Overview</router-link
                                    >
                                    <router-link
                                        to="/contracts"
                                        class="text-base font-medium text-primary-400 hover:text-primary-700"
                                        >Flexibility Contracts</router-link
                                    >
                                </div>
                            </div>
                        </nav>
                    </div>

                    <transition
                        enter-active-class="duration-150 ease-out"
                        enter-class="scale-95 opacity-0"
                        enter-to-class="scale-100 opacity-100"
                        leave-active-class="duration-100 ease-in"
                        leave-class="scale-100 opacity-100"
                        leave-to-class="scale-95 opacity-0"
                    >
                        <div
                            v-if="isMenuOpen"
                            class="absolute inset-x-0 top-0 p-2 transition origin-top transform md:hidden"
                        >
                            <div class="overflow-hidden bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
                                <div class="flex items-center justify-end px-5 pt-4">
                                    <div class="-mr-2">
                                        <button
                                            type="button"
                                            class="inline-flex items-center justify-center p-2 text-neutral-400 bg-white rounded-md hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-600"
                                            @click="isMenuOpen = false"
                                        >
                                            <span class="sr-only">Close</span>
                                            <XIcon class="w-6 h-6" />
                                        </button>
                                    </div>
                                </div>
                                <div class="pt-5 pb-6">
                                    <div v-if="isAuthenticated" class="px-2 space-y-1">
                                        <router-link
                                            to="/overview"
                                            class="block px-3 py-2 text-base font-medium text-neutral-900 rounded-md hover:bg-neutral-50"
                                            >Overview</router-link
                                        >
                                        <router-link
                                            to="/contracts"
                                            class="block px-3 py-2 text-base font-medium text-neutral-900 rounded-md hover:bg-neutral-50"
                                            >Flexibility Contracts</router-link
                                        >
                                    </div>
                                    <div v-if="!isAuthenticated" class="px-5" :class="{ 'mt-5': isAuthenticated }">
                                        <router-link
                                            to="/login"
                                            class="block w-full px-5 py-3 font-medium text-center transition duration-150 ease-in-out text-primary-600 bg-primary-100 hover:bg-neutral-100 hover:text-primary-700 focus:outline-none focus:bg-neutral-100 focus:text-primary-700"
                                        >
                                            Log in
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </Popover>

                <main class="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                    <div class="sm:text-center lg:text-left">
                        <h1 class="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl">
                            <span
                                class="block py-1 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-primary-700 to-primary-500"
                                >EXCESS</span
                            >
                            <span class="block text-secondary-600">Explicit Demand Response application</span>
                        </h1>
                        <p
                            class="mt-3 text-base text-neutral-700 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
                        >
                            Welcome to the EDR app, the blockchain-powered application that brings together the Building
                            Occupants and the Aggregators
                        </p>
                    </div>
                </main>
            </div>
        </div>

        <div
            v-if="!isAuthenticated"
            class="hidden px-8 py-6 md:absolute md:flex md:items-start md:inset-y-0 md:right-0 z-50"
        >
            <router-link
                to="/login"
                class="px-4 py-2 ml-8 font-medium transition duration-150 ease-in-out border-2 rounded-lg text-white border-secondary-600 bg-secondary-600 hover:border-transparent hover:bg-secondary-700 hover:text-white focus:outline-none focus:bg-secondary-400 focus:text-white"
            >
                Log in
            </router-link>
        </div>

        <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img class="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full" src="/img/contract.jpg" alt="" />
        </div>
    </div>
</template>

<script>
import { Popover } from '@headlessui/vue';
import { defineComponent, ref, computed } from 'vue';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import Keycloak from '@/api/keycloak';
import store from '../store';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export default defineComponent({
    name: 'Home',
    metaInfo: {
        title: 'Home',
    },
    components: { MenuIcon, XIcon, Popover },
    setup() {
        const router = useRouter();
        const toast = useToast();
        const isMenuOpen = ref(false);
        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const useKeycloak = computed(() => Keycloak.isEnabled());
        const isLoggingIn = ref(false);

        const login = async () => {
            isLoggingIn.value = true;
            try {
                await Keycloak.login();
            } catch (e) {
                toast.error(e.message, 'Error');
            } finally {
                isLoggingIn.value = false;
            }
        };

        const keycloakAuthentication = async () => {
            if (isAuthenticated.value) {
                router.push({ name: 'home', hash: '' });
            } else {
                isLoggingIn.value = true;
                try {
                    await Keycloak.login();
                } catch (e) {
                    toast.error(e.message, 'Error');
                } finally {
                    isLoggingIn.value = false;
                }
            }
        };

        if (
            useKeycloak.value &&
            document.location.hash.length > 0 &&
            document.location.hash.match(/^#.*code=[a-zA-Z0-9]*/g)
        ) {
            keycloakAuthentication();
        }

        return { isAuthenticated, isMenuOpen, useKeycloak, login };
    },
});
</script>
